/* Global Styles */
:root {
  --primary-color: red;
  --dark-color: #1d3030;
  --light-color: white;
  --danger-color: crimson;
  --success-color: springgreen;
  --warning-color: gold;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Lexend Exa', sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  background: url('./assets/images/escheresque_ste.png') center repeat;
  color: var(--light-color);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Lexend Exa', sans-serif;
  line-height: 1.5rem;
  background: none;
}

a {
  color: var(--light-color);
  text-decoration: none;
}

a:hover {
  color: var(--primary-color);
}

ul {
  list-style: none;
}

.wrapper {
  max-width: 100%;
}

/* Image Styles */
img {
  width: 100%;
}

.about-image {
  transition: all 0.2s ease-in-out;
}

.about-image:hover {
  transform: scale(1.15);
  cursor: pointer;
}

.header-image {
  max-height: 55vh;
  overflow: hidden;
  vertical-align: middle;
}

.landing1 {
  position: relative;
  background: url('./assets/images/StockTruck7.jpg') no-repeat center
    center/cover;
  height: 80vh;
}

.landing2 {
  position: relative;
  background: url('./assets/images/StockTruck6.jpg') no-repeat center
    center/cover;
  height: 80vh;
}

.landing3 {
  position: relative;
  background: url('./assets/images/StockTruck8.jpg') no-repeat center
    center/cover;
  min-height: 140vh;
}

.landing4 {
  position: relative;
  background: url('./assets/images/StockTruck5.jpg') no-repeat center
    center/cover;
  height: 135vh;
}

.landing-inner {
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.darker-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Background Styles */
.bg-primary {
  background-color: var(--primary-color);
  color: var(--light-color);
}

.bg-light {
  background-color: var(--light-color);
  color: var(--dark-color);
}

.bg-dark {
  background-color: var(--dark-color);
  color: var(--light-color);
}

.bg-success {
  background-color: var(--success-color);
  color: var(--dark-color);
}

.bg-danger {
  background-color: var(--danger-color);
  color: var(--light-color);
}

.bg-warning {
  background-color: var(--warning-color);
  color: var(--dark-color);
}

/* Text Styles  */
.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-light {
  color: var(--light-color);
}

/* Button Styles */
.button {
  margin: 1rem;
  width: 100px;
}

.delete-button {
  cursor: pointer;
}

.delete-account {
  margin-top: 25px;
  margin-bottom: 25px;
}

/* Alert Styles */
.alert {
  max-width: 100%;
  padding: 0.8rem;
  margin-bottom: 1.2rem;
  background: var(--light-color);
  color: var(--dark-color);
  font-weight: bold;
}

.alert.alert-success {
  background-color: var(--success-color);
  color: var(--dark-color);
}

.alert.alert-danger {
  background-color: var(--danger-color);
  color: var(--light-color);
}

.alert.alert-warning {
  background-color: var(--warning-color);
  color: var(--dark-color);
}

/* Nav Styles */
#page-wrap {
  height: 110vh;
  overflow: auto;
}

.bm-item {
  display: inline-block;

  text-decoration: none;
  margin-bottom: 2rem;
  color: var(--light-color);
  transition: color 0.2s;
}

.bm-item:hover {
  color: var(--primary-color);
}

.bm-item:focus {
  outline: 0;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
  color: var(--primary-color);
}

.bm-burger-bars {
  background: var(--primary-color);
}

.bm-burger-bars:focus {
  outline: 0;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
  margin-top: 1rem;
}

.bm-cross {
  background: var(--light-color);
}

.bm-menu {
  background: url('./assets/images/escheresque_ste.png') center repeat;
  padding: 3em 1.5em 0;
  font-size: 1.15em;
  height: 110vh;
}

.bm-morph-shape {
  fill: var(--dark-color);
}

.bm-item-list {
  color: var(--light-color);
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  height: 110vh;
}

.bm-overlay:hover {
  cursor: pointer;
}

.menu-logo {
  margin-bottom: 2rem;
}

.menu-link {
  margin-bottom: 1rem;
}

.contact-button:hover {
  cursor: pointer;
  color: var(--primary-color);
}

/* Footer Styles */
.page-footer {
  height: auto;
}

/* Dashboard Styles */
.profile-list {
  line-height: 1.5rem;
}

/* Video Styles */
.video-box {
  max-height: 105vh;
  min-height: 20vh;
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.top-video {
  position: relative;
  height: auto;
  width: 100%;
  display: block;
}

.video-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.video-small {
  display: none;
}

/* Map Styles */
.leaflet-container {
  position: relative;
  height: 500px;
  width: 100%;
  display: inline-block;
  overflow: hidden;
}

.map-container {
  position: relative;
  display: block;
}

.rate-request {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  display: flex;
}

.map-text {
  padding: 1rem;
  text-align: justify;
}

/* Misc Styles */
.grid {
  padding: 2rem;
}

.grid-left {
  padding-left: 2rem;
  position: absolute;
  bottom: 2rem;
}

.grid-right {
  padding-right: 1.5rem;
  position: absolute;
  bottom: 2rem;
  right: 1rem;
}

.page-not-found {
  height: 400px;
  margin: 25px;
  text-align: center;
}

.parallax-text {
  padding: 20;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Slider Styles */
.sl-content {
  text-align: center;
}

.sl-inner {
  box-sizing: border-box;
  position: absolute;
  top: 40%;
  left: 10%;
  width: 80%;
}

.sl-inner p {
  font-style: italic;
  margin: 2rem;
}

/* MOBILE STYLES */
@media (max-width: 767px) {
  .add-header {
    line-height: 2.5rem;
  }

  .landing1 {
    position: relative;
    background: url('./assets/images/StockTruck7.jpg') no-repeat center
      center/cover;
    height: 100vh;
  }

  .landing2 {
    position: relative;
    background: url('./assets/images/StockTruck6.jpg') no-repeat center
      center/cover;
    height: 100vh;
  }

  .landing3 {
    position: relative;
    background: url('./assets/images/StockTruck8.jpg') no-repeat center
      center/cover;
    min-height: 220vh;
  }

  .landing4 {
    position: relative;
    background: url('./assets/images/StockTruck5.jpg') no-repeat center
      center/cover;
    height: 150vh;
  }
}

@media (max-width: 600px) {
  .video-inner {
    display: none;
  }

  .video-small {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
